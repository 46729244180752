// import * as jQuery from 'jquery'
const jQuery = require('jquery')
import Scrollbar from 'smooth-scrollbar'
import './babel'
import { Fancybox } from "@fancyapps/ui"
import 'jquery.cookie'
import "@fancyapps/ui/dist/fancybox.css"
import "remodal/dist/remodal.css"
import "remodal/dist/remodal-default-theme.css"
import "remodal/dist/remodal.min.js"
import Swiper from 'swiper/bundle'
import 'swiper/css/bundle'

global.jQuery = jQuery
global.Fancybox = Fancybox
global.Swiper = Swiper
global.Scrollbar = Scrollbar

jQuery(document).ready(function($) {
    $('.remodal').remodal();

    new Swiper('.swiper-products', {
        slidesPerView: 'auto',
        centerInsufficientSlides: true,
    });

    new Swiper('.swiper-slider', {
        slidesPerView: 1,
        loop: true,
        autoplay: {
            delay: 7000,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
        },
    });

    $('body').on('click', '.search-open', function() {
        $('.search-block').fadeIn(150);
    });
    $('body').on('click', '.menu-open', function() {
        $(this).removeClass('menu-open header-mobile__menu-button').addClass('menu-close header-mobile__close-menu-button');
        $('.menu-block').slideDown();
    });
    $('body').on('click', '.menu-close', function() {
        $(this).removeClass('menu-close header-mobile__close-menu-button').addClass('menu-open header-mobile__menu-button');
        $('.menu-block').slideUp();
    });

    // Клик вне элемента
    var iclick = false;
    $('body').on('mousedown', function(event) {
        iclick = event.target;
    });
    $('body').on('mouseup', function(event) {
        let block, button;
        let target = iclick !== false ? iclick : event.target;

        if ($('.loader-background').is(target)) {
            return false;
        }

        block = $('.search-block');
        if (block.length && !block.is(target) && block.has(target).length === 0) {
            block.fadeOut(150);
        }
    });

    let sidebarMenus = document.querySelectorAll('.sidebar__menu.catalog-menu');
    if (sidebarMenus.length) {
        for (let i = 0; i < sidebarMenus.length; i++) {
            Scrollbar.init(sidebarMenus[i], {
                alwaysShowTracks: true,
                thumbMinSize: 8,
            });
        }
    }

    $('body').on('mouseover', '.catalog-menu li', function() {
        let ul = $(this).find('ul');
        $(this).closest('.sidebar__wrapper').nextAll('.sidebar__wrapper').remove();
        $(this).siblings('li').removeClass('active');
        if (ul.length) {
            let menu = $('<div />').addClass('sidebar__wrapper sidebar__wrapper--children');
            let menuContent = $('<div />').addClass('sidebar__menu catalog-menu');
            menuContent.html(ul.html());
            Scrollbar.init(menuContent.get(0), {
                alwaysShowTracks: true,
                thumbMinSize: 8,
            });
            menu.html(menuContent);
            $(this).closest('.sidebar__wrapper').after(menu);
            $(this).addClass('active');
        }
    });

    $('body').on('mouseleave', '.sidebar__wrapper--children', function(event) {
        if (
            !$(event.relatedTarget).hasClass('sidebar__wrapper') &&
            !$(event.relatedTarget).closest('.sidebar__wrapper').length
        ) {
            $('.sidebar__wrapper').find('li').removeClass('active');
            $('.sidebar__wrapper--children').remove();
        }
    });

    $('body').on('mouseleave', '.sidebar__wrapper:not(.sidebar__wrapper--children)', function(event) {
        if (
            !$(event.relatedTarget).hasClass('sidebar__wrapper') &&
            !$(event.relatedTarget).closest('.sidebar__wrapper').length
        ) {
            $(this).nextAll('.sidebar__wrapper').remove();
        }
    });

    Fancybox.bind(".product-detail__gallery a", {
        groupAll : true,
        on : {
            load: (fancybox, slide) => {
                setTimeout(() => {
                    let button = $('.product-detail__button.button-detail').eq(0).clone(true);
                    button.addClass('gallery-button');
                    $(slide.$content).after(button);
                }, 500);
            },
            closing: (fancybox, slide) => {
                $(fancybox.$container).find('.gallery-button').remove();
            }
        }
    });

    $('body').on('click', '.tabs__button', function() {
        let id = $(this).data('tab_open_id');
        let parent = $(this).closest('.tabs');
        let tabs_id = parent.data('tabs_id');
        setTab(parent, tabs_id, id);
    });

    $('.tabs').each(function(elem, index) {
        let tabs_id = $(this).data('tabs_id');
        let id = $.cookie('tabs_'+tabs_id);
        if (id) {
            setTab($(this), tabs_id, id, false);
        }
    });

    function setTab(tabs, tabs_id, tab_id, cookie = true) {
        $('.tabs__button').removeClass('active');
        $(this).addClass('active');
        tabs.find('.tabs__button').removeClass('active');
        tabs.find('.tabs__button[data-tab_open_id="'+tab_id+'"]').addClass('active');
        tabs.find('.tabs__panel').removeClass('active');
        tabs.find('.tabs__panel[data-tab_id="'+tab_id+'"]').addClass('active');
        if (cookie) {
            $.cookie('tabs_'+tabs_id, tab_id);
        }
    }

    $('body').on('click', '.menu-dropdown-icon', function(event) {
        event.stopPropagation();
        event.preventDefault();
        let ul = $(this).closest('li').find('ul');
        if (ul.hasClass('active')) {
            ul.removeClass('active').slideUp();
            $(this).removeClass('active');
        } else {
            ul.addClass('active').slideDown();
            $(this).addClass('active');
        }
    });

    $('body').on('click', '.dropdown-model-btn', function() {
        $(this).siblings('.dropdown-model-wrapper').addClass('open');
        $(this).addClass('open');
    });

    $('body').on('click', '.counter__plus', function() {
        let $block = this.closest('.counter');
        let $input = $block.querySelector('.counter__value');
        if ($input.value >= parseInt($input.max)) {
            $input.value = $input.max;
            return false;
        }
        $input.value++;
        $('body').trigger('plus-in-stock');
    });

    $('body').on('click', '.counter__minus', function() {
        let $block = this.closest('.counter');
        let $input = $block.querySelector('.counter__value');
        if ($input.value < 2) {
            $input.value = 1;
            return false;
        }
        $input.value--;
        $(document).trigger('minus');
    });

    $('body').on('input', '.counter__value', function () {
        this.value = this.value.replace(/[^0-9]/g, '');
        if (this.value > parseInt(this.max)) {
            this.value = this.max;
        }
        if (this.value < 1) {
            this.value = 1;
        }
    });

    // $('body').on('plus-in-stock', function() {
    //     console.log('plus!!!');
    //     console.log(this);
    // });

    /* перенос 17.01.2023 */
    var ls = localStorage;

    var bg = {
        open: function() {
            $('.bg').fadeIn();
        },
        close: function() {
            $('.bg').fadeOut();
        }
    };

    var order = {
        get: function() {
            let order = ls.getItem('order');
            return order ? JSON.parse(order) : {};
        },
        set: function(order) {
            ls.setItem('order', JSON.stringify(order));
        },
        add: function(name, value, one = true) {
            let o = order.get();
            if (/\[\]$/.test(name)) {
                if (one === false && o[name]) {
                    if (o[name].indexOf(value) == -1) {
                        o[name].push(value);
                    }
                } else {
                    o[name] = [value];
                }
            } else {
                o[name] = value;
            }
            order.set(o);
        },
        remove: function(name, value) {
            let o = order.get();
            if (/\[\]$/.test(name)) {
                if (o[name]) {
                    let index = o[name].indexOf(value);
                    if (index >= 0) {
                        o[name].splice(index, 1);
                    }
                    if (o[name].length === 0) {
                        delete o[name];
                    }
                }
            } else {
                delete o[name];
            }
            order.set(o);
        }
    };

    document.addEventListener('submit', eventFormSubmit);

    function eventFormSubmit(event) {
        let formData, isAjax;
        isAjax = event.target.hasAttribute('ajax') ? event.target.getAttribute('ajax') : true;
        if (isAjax == 'false' || isAjax == 'any') {
            return;
        }
        event.preventDefault();
        formData = new FormData(event.target);
        formData.append(event.submitter.name, event.submitter.value);
        asyncFunc(event.target.action, event.target.method, formData.toJSON());
    }
    
    function formSubmit(detail) {
        let formData, isAjax, form;
        form = this;
        isAjax = form.hasAttribute('ajax') ? form.getAttribute('ajax') : true;
        if (isAjax == 'false') {
            return;
        }
        formData = new FormData(form);
        if (detail.submitter) {
            formData.append(detail.submitter.name, detail.submitter.value);
        }
        asyncFunc(form.action, form.method, formData.toJSON());
    }

    let ajax = false;

    function asyncFunc(action = null, method = null, body = null) {
        if (ajax) return false;
        ajax = true;
        $.ajax({
            url: action,
            method: method,
            dataType: 'json',
            data: body,
            success: function(response) {
                console.log(response);
                if (!response) {
                    throw new Error('Сервер не вернул данных');
                }
                if (response.status == 'error') {
                    if (response.events) {
                        response.events.forEach(event => {
                            var customEvent = new CustomEvent(event, {detail : response});
                            document.dispatchEvent(customEvent);
                        });
                    }
                    var customEvent = new CustomEvent('error', {detail : {
                        text: response.text,
                        fields_message: response.fields_message ?? '',
                    }});
                    document.dispatchEvent(customEvent);
                } else if (response.status == 'success') {
                    if (response.events) {
                        response.events.forEach(event => {
                            var customEvent = new CustomEvent(event, {detail : response});
                            document.dispatchEvent(customEvent);
                        });
                    }
                    if (response.text) {
                        var customEvent = new CustomEvent('modal', {detail : {
                            text: response.text,
                            fields_message: response.fields_message ?? '',
                        }});
                        document.dispatchEvent(customEvent);
                    }
                } else {
                    throw new Error('Неизвестная ошибка');
                }
            },
            error: function(xhr, type, message) {
                console.error(message);
                if (xhr.status == 422) {
                    let text = Object.values(JSON.parse(xhr.responseText).errors)[0][0];
                    var customEvent = new CustomEvent('error', {detail : {
                        text: JSON.parse(xhr.responseText).errors,
                    }});
                    document.dispatchEvent(customEvent);
                }
            },
            complete: function(data) {
                ajax = false;
                $('.bg').fadeOut();
            }
        });
    }

    $(document).on('error modal', function(event) {
        let item;
        if (event.detail.fields_message) {
            for (item in event.detail.fields_message) {
                $('.'+item).text(event.detail.fields_message[item]);
            }
        } else if (event.detail.text && typeof event.detail.text == 'object') {
            for (item in event.detail.text) {
                $('[name="'+item+'"]').removeClass('error').addClass('error');
            }
        } else {
            if (event.detail.html) {
                $('.modal-content').html(event.detail.html);
            } else {
                $('.modal-content').text(event.detail.text);
            }
            let modal = $('.modal').remodal();
            modal.open();
        }
    });

    $(document).on('product-added', function(event) {
        let element = $('.product-detail__control input[value="'+event.detail.element_id+'"]');
        let control = $('.product-detail__control');
        if (element.length) {
            control = element.closest('.product-detail__control');
        }
        $('.basket-item').removeClass('load');
        control.find('.product-detail__added_control').removeClass('visible');
        control.find('.product-detail__added_control.added').addClass('visible');
    });

    $(document).on('update-basket-count', function(event) {
        $('.basket-count').text(event.detail.basketCount);
    });

    $(document).on('update-total-sum', function(event) {
        let sum = event.detail.totalSum ?? 0;
        sumStopLoader(sum);
    });

    $(document).on('remove-product', function(event) {
        $('.removed').fadeOut('400', function() {
            $(this).remove();
            if (event.detail && event.detail.emptyText) {
                $('.basket__list').text(event.detail.emptyText);
            }
        });
    });

    $(document).on('basket-item-loader', function(event) {
        $('.basket-item').removeClass('load');
    });

    function calcSum(detail) {
        if ($(this).closest('.basket-item')) {
            let parent = $(this).closest('.basket-item');
            let basket_sum = parent.find('.basket-sum');
            if (basket_sum.length) {
                let price = parseFloat(basket_sum.data('price'));
                let count = 0;
                parent.find('.product-stock').each(function(item, index) {
                    let checkbox = $(this).find('.stock-active');
                    if (checkbox && checkbox.prop('checked')) {
                        count += parseInt($(this).find('.counter__value').val()) ?? 0;
                    }
                });
                count = count ?? 1;
                basket_sum.text((price * count).moneyFormat());
                if (count === 0) {
                    if (!parent.find('.basket__item_sum').hasClass('hidden')) {
                        parent.find('.basket__item_sum').add('hidden');
                    }
                } else {
                    parent.find('.basket__item_sum').removeClass('hidden');
                }
            }
        }
        if ($('.basket-page').length) {
            formSubmit.call(this.closest('form'), detail);
        }
    }

    let waitTimeout = false;
    $(document).on('change', '.product-stock__label input[type="checkbox"]', function() {
        hideBasketItemError();
        let _this = this;
        let basketPage = $('.basket-page').length ? true : false;
        if (basketPage) {
            clearTimeout(waitTimeout);
            waitTimeout = setTimeout(() => {
                sumStartLoader();
                calcSum.call(_this, {element: _this, submitter: {name: 'product-add', value: 1}});
            }, 500);
        } else {
            calcSum.call(_this, {element: _this, submitter: {name: 'product-add', value: 1}});
        }
    });
    $(document).on('click', '.counter__plus, .counter__minus', function() {
        let _this = this;
        let basketPage = $('.basket-page').length ? true : false;
        if (basketPage) {
            clearTimeout(waitTimeout);
            waitTimeout = setTimeout(() => {
                sumStartLoader();
                calcSum.call(_this, {element: _this, submitter: {name: 'product-add', value: 1}});
            }, 500);
        } else {
            calcSum.call(_this, {element: _this, submitter: {name: 'product-add', value: 1}});
        }
    });
    
    $('.basket__item_delete').on('click', function() {
        sumStartLoader();
        $(this).closest('.basket-item').addClass('removed');
    });

    $('body').on('click', 'button[name="product-add"]', function(event) {
        if (!$(this).closest('form').find('.stock-active:checked').length) {
            event.preventDefault();
            showBasketItemError('Выберите склад');
            return false;
        }
        sumStartLoader();
    });

    function sumStartLoader() {
        $('.basket-item').addClass('load');
        $('.total-sum').text('').addClass('loader');
    }

    function sumStopLoader(sum) {
        $('.basket-item').removeClass('load');
        $('.total-sum').text(sum.moneyFormat()).removeClass('loader');
    }

    function showBasketItemError(message) {
        let messageBlock = $('.product-detail__message');
        if (messageBlock.hasClass('visible')) {
            messageBlock.text(message);
        } else {
            messageBlock.text(message).addClass('visible');
        }
    }

    function hideBasketItemError() {
        $('.product-detail__message').removeClass('visible');
    }

    $('body').on('click', '.button-basket-next-step', function(event) {
        if (!$('.stock-active:checked').length) {
            event.preventDefault();
        }
    });

    $(document).on('update-order-parameters', function(event) {
        if (event.detail.orderParameters && $('.order-parameters')) {
            $('.order-parameters').replaceWith(event.detail.orderParameters);
        }
    });

    $('body').on('change', '.radio input', function(event) {
        if (this.type == 'radio') {
            if (this.dataset.visible) {
                let visibleObjects = this.dataset.visible.split(',');
                visibleObjects.forEach(element => {
                    let o = order.get();
                    $('.group-'+element).removeClass('hidden').find('input').prop('disabled', false).each(function() {
                        // if (this.type == 'checkbox' || this.type == 'radio') {
                        //     if (o[this.name] && o[this.name].indexOf(this.value) >= 0) {
                        //         this.checked = true;
                        //     }
                        // } else {
                        //     this.value = o[this.name];
                        // }
                    });
                });
            }
            if (this.dataset.hidden) {
                let hiddenObjects = this.dataset.hidden.split(',');
                hiddenObjects.forEach(element => {
                    $('.group-'+element).addClass('hidden').find('input').prop('disabled', true).each(function() {
                        // this.checked = false;
                    })
                });
            }
        }
    });

    var searchTimeout = false;
    $('body').on('input', '[name="search"]', function(event) {
        let _this = this;
        clearTimeout(searchTimeout);
        searchTimeout = setTimeout(() => {
            formSubmit.call(this.closest('form'), {
                'test': false
            });
        }, 1000);
    });

    $('body').on('input', '.basket-page input, .basket-page textarea', function(event) {
        let types = [
            'checkbox',
            'radio',
        ];
        if (types.indexOf(event.target.type) == -1) {
            if (event.target.value) {
                let formCookie = getCookie('form');
                if (formCookie) {
                    formCookie = JSON.parse(formCookie);
                } else {
                    formCookie = {};
                }
                formCookie[event.target.name] = event.target.value;
                formCookie = JSON.stringify(formCookie);
                setCookie('form', formCookie);
            } else {
                let formCookie = getCookie('form');
                if (formCookie) {
                    formCookie = JSON.parse(formCookie);
                    let index = formCookie.indexOf(event.target.value);
                    if (index >= 0) {
                        formCookie.splice(index, 1);
                    }
                }
                if (formCookie.length) {
                    setCookie('form', JSON.stringify(formCookie));
                } else {
                    deleteCookie('form');
                }
            }
        }
    });

    $('body').on('click', '.city__current', function() {
        $('.city__list .select').toggleClass('open');
    });

    $('body').on('change', '.order-remote-form input[name="delivery"]', function(event) {
        switch (this.value) {
            case 'delivery_ems':
                ems_calc(pochta_widget_data.ems);
                break;
            case 'delivery_pochta':
                pochta_calc(pochta_widget_data.pochta);
                break;
        }
    });

    $('body').on('submit', '.order-remote-form', function(event) {
        if (!$('[name="ems_address"]').val()) {
            event.preventDefault();
            event.stopPropagation();
        }
    });
    $('body').on('submit', '.order-form', function(event) {
        let elements = $('[name="payment"]:checked:not(:disabled)');
        if (!elements.length) {
            event.preventDefault();
            event.stopPropagation();
            let field = $('[name="payment"]:valid:not(:disabled)').get(0);
            if (field) {
                field.setCustomValidity('Выберите способ оплаты');
            }
        }
    });

    $(document).on('search', function(event) {
        $('.search .select-wrapper').remove();
        $('.search').append(event.detail.products);
    });

    var placemarks = [];
    let coords, lat, long, data, center, myMap, str, zoom;
    $('.contacts__item').each(function (key, value) {
        coords = $(value).data('coords').split(', ');
        lat = parseFloat(coords[0]);
        long = parseFloat(coords[1]);
        data = {
            coords: [lat, long]
        };
        placemarks.push(data);
    })
    if (placemarks.length != 0) {
        center = placemarks[0].coords;
        ymaps.ready(init);

        function init() {
            myMap = new ymaps.Map("map", {
                center: [48.473503, 135.084242],
                zoom: 12,
                controls: []
            });
            var placemark = [];
            $.each(placemarks, function (key, value) {
                placemark[key] = new ymaps.Placemark(value.coords, {}, {
                    iconLayout: 'default#image',
                    iconImageHref: "/assets/tmp/map-marker.png",
                    iconImageSize: [50, 58],
                    iconImageOffset: [-22, -58]
                });
                myMap.geoObjects.add(placemark[key]);
            })

            $('.contacts__item').click(function () {
                str = $(this).data('coords').split(',');
                center = [parseFloat(str[0]), parseFloat(str[1])];
                zoom = myMap.getZoom();
                myMap.setCenter(center, zoom, {duration: 500});
                var items = $('.contacts__item');
                if (!$(this).hasClass('contacts__item_active')) {
                    items.removeClass('contacts__item_active');
                    $(this).addClass('contacts__item_active');
                }
            });
        }
    }

    $('body').on('click', '.clone', function(event) {
        event.preventDefault();
        let group = $(this).closest('form').find('.clone-group');
        let newGroup = group.eq(0).clone();
        newGroup.removeClass('clone-original').find('input').val('');
        group.eq(group.length - 1).after(newGroup);
    });

    $('body').on('click', '.clone-remove', function() {
        $(this).closest('.clone-group').fadeOut(200, function() {
            $(this).remove();
        });
    });

    $('input[name="phone"], input[name="complex-phone"]').mask('+7 999 999-99-99');

    $('body').on('click', '.catalog-menu-open', function() {
        $('.sidebar__wrapper.first').toggle();
        Scrollbar.init($('.sidebar__wrapper.first .catalog-menu').get(0), {
            alwaysShowTracks: true,
            thumbMinSize: 8,
        });
    });

    $(document).on('filter', function(event) {
        $('.category-products-list').replaceWith(event.detail.html);
        $('.remodal').remodal();
    });

    $(document).on('filter-clear', function(event) {
        $('.range').each(function(ind, el) {
            let min = $(el).data('min');
            let max = $(el).data('max');
            $(el).find('.range_from').text(min);
            $(el).find('.range_to').text(max);
            $(el).find('.range__left span').text(min);
            $(el).find('.range__right span').text(max);
            $(el).find('.range__input').slider('values', [min, max]);
        });
        $('.filter input[type="checkbox"]').prop('checked', false);
        $('.filter input[type="radio"]').prop('checked', false);
        $('.filter input[type="radio"]').eq(0).prop('checked', true);
    });

    $('.range').each(function(ind, el) {
        let min = $(el).data('min');
        let max = $(el).data('max');
        let curMin = $(el).find('.range_from').val();
        let curMax = $(el).find('.range_to').val();
        $(el).find('.range__input').slider({
            range: true,
            min: min,
            max: max,
            values: [curMin, curMax],
            slide: function(event, ui) {
                $(el).find('.range_from').val(ui.values[0]);
                $(el).find('.range__left span').text(ui.values[0]);
                $(el).find('.range_to').val(ui.values[1]);
                $(el).find('.range__right span').text(ui.values[1]);
            }
        });
    });

    $('body').on('input', 'input[name="email"]', function(e) {
        if (this.required) {
            if (!validateEmail($(this).val())) {
                this.setCustomValidity('Введите кооректную эл. почту');
            } else {
                this.setCustomValidity('');
            }
        }
    });

    function validateEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    $(document).on('callme', function(event) {
        let block = $('.sticky-block__success');
        let time = block.data('time');
        block.show();
        setTimeout(() => {
            block.hide();
        }, time);
    });

    $('body').on('click', '.password-eye', function(event) {
        let input = $(this).closest('.password-eye-wrapper').find('input').get(0);
        input.type = input.type == 'password' ? 'text' : 'password';
    });

    $('.error').each(function(ind, el) {
        let form = $(el).data('form');
        let modal;
        if (form) {
            modal = $(form).remodal();
            modal.open();
            return false;
        }
    });

    $('body').on('click', '.call-me-button', function(event) {
        let form = $('.sticky-block__form');
        if (form.length) {
            form = form.get(0);
            formCheckValidity(form);
            if(form.checkValidity()) {
                formSubmit.call(form, {element: form, submitter: {name: 'call-me-button', value: 'call'}});
            }
        }
    });

    $('body').on('input change', '.sticky-block__field', function() {
        formFieldValidity(this);
    });

    function formCheckValidity(form) {
        Array.prototype.forEach.call(form.elements, function(field) {
            formFieldValidity(field);
        });
    }

    function formFieldValidity(field) {
        if (!field.validity.valid) {
            if (!field.classList.contains('error')) {
                field.classList.add('error');
            }
        } else {
            field.classList.remove('error');
        }
    }

    $(document).on('recaptcha-reset', function(event) {
        $('.callme-recaptcha-error').text('');
        if (window.callmeRecaptchaID) {
            window.callmeRecaptchaID.forEach(function(el) {
                grecaptcha.reset(el);
            });
        }
    })

    $(document).on('callme-close-modal', function(event) {
        let block = $('[data-remodal-id="modal-captcha"]');
        if (block.length) {
            block.remodal().close();
        }
    });

    $('body').on('click', '.select-button', function() {
        $(this).closest('.select-dropdown').find('.select').toggleClass('open');
    });

    $('body').on('click', '.select-dropdown .option', function() {
        let parent = $(this).closest('.select-dropdown');
        let value = $(this).data('value');
        let stock = $(this).text();
        parent.find('.select-value').val(value).removeClass('error');
        parent.find('.select-button').text(stock);
        parent.find('.select').removeClass('open');
    });
})

window.getCookie = getCookie;
function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

window.setCookie = setCookie;
function setCookie(name, value, options = {}) {

    options = {
        path: '/',
        // при необходимости добавьте другие значения по умолчанию
        ...options
    };

    if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString();
    }

    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

    for (let optionKey in options) {
        updatedCookie += "; " + optionKey;
        let optionValue = options[optionKey];
        if (optionValue !== true) {
            updatedCookie += "=" + optionValue;
        }
    }

    document.cookie = updatedCookie;
}

window.deleteCookie = deleteCookie;
function deleteCookie(name) {
    setCookie(name, "", {
        'max-age': -1
    })
}

FormData.prototype.toJSON = function() {
    let object = {};
    let reg = /\[(.+)?\]/;
    for (var key of this.keys()) {
        let searchIndex = reg.exec(key);
        if (searchIndex) {
            let newKey = key.replace(reg, '');
            if (!object[newKey]) object[newKey] = {};
            object[newKey][searchIndex[1]] = this.get(key);
        } else {
            object[key] = this.get(key);
        }
    }
    return object;
}
Number.prototype.moneyFormat = moneyFormat;
String.prototype.moneyFormat = moneyFormat;

function moneyFormat() {
    let number = this.toString().replace(/[^0-9\.\,\-]/, '');
    return new Intl.NumberFormat('ru-RU').format(number);
};

window.pochta_widget_data = pochta_widget_data;
var pochta_widget_data = {
    'ems': '',
    'pochta': '',
};

window.ems_calc_clear = ems_calc_clear;
function ems_calc_clear() {
    jQuery('.ems_delivery').remove();
    jQuery('[name="ems_address"]').val('');
    jQuery('[name="ems_comment"]').val('');
    jQuery('[name="ems_weight"]').val('');
    jQuery('[name="ems_delivery_amount"]').val('');
    jQuery('[name="ems_total_amount"]').val('');
    jQuery('.ems_delivery_markup').text('');
    jQuery('[name="ems_dalivery_date_description"]').val('');
    jQuery('[name="mailType"]').val('');
}

window.ems_calc = ems_calc;
function ems_calc(data) {
    ems_calc_clear();
    jQuery('[name="ems_delivery_type"]').val(1);
    if (!data) return;
    pochta_widget_data.ems = data;
    var delivery = parseFloat(data.cashOfDelivery / 100);
    var total = parseFloat((totalcost_in_cents + data.cashOfDelivery) / 100);
    var weight = parseFloat(totalcost_in_grams / 1000);

    jQuery('.ems_delivery').remove();

    jQuery('.group-remote-total').replaceWith(`
        <div class="group-remote-total">
            <div class="basket__total">
                <div class="basket__total_label">Вес товаров:</div>
                <div class="basket__total_sum total-sum">`+weight+`</div>
                <div class="basket__total_symbol">₽</div>
            </div>
            <div class="basket__total">
                <div class="basket__total_label">Доставка:</div>
                <div class="basket__total_sum total-sum">`+delivery+`</div>
                <div class="basket__total_symbol">₽</div>
            </div>
            <div class="basket__total">
                <div class="basket__total_label">Итого:</div>
                <div class="basket__total_sum total-sum">`+total+`</div>
                <div class="basket__total_symbol">₽</div>
            </div>
        </div>
    `);

    jQuery('[name="ems_address"]').val(data.indexTo+', '+data.regionTo+', '+data.areaTo+', '+data.cityTo+', '+data.addressTo);
    jQuery('[name="ems_comment"]').val(data.comment);
    jQuery('[name="ems_weight"]').val(weight);
    jQuery('[name="ems_delivery_amount"]').val(delivery);
    jQuery('[name="ems_total_amount"]').val(total);
    jQuery('[name="ems_mail_type"]').val(data.mailType);

    let from = parseInt(data.delivery.values.deliveryMin) + 2;
    let to = parseInt(data.delivery.values.deliveryMax) + 2;
    let days_delivery = 'От '+from+' до '+to+'-x дней';

    jQuery('.ems_delivery_markup').text(days_delivery);
    jQuery('[name="ems_dalivery_date_description"]').val(days_delivery);
}

window.pochta_calc = pochta_calc;
function pochta_calc(data) {
    ems_calc_clear();
    jQuery('[name="ems_delivery_type"]').val(2);
    if (!data) return;
    pochta_widget_data.pochta = data;
    var delivery = parseFloat(data.cashOfDelivery / 100);
    var total = parseFloat((totalcost_in_cents + data.cashOfDelivery) / 100);
    var weight = parseFloat(totalcost_in_grams / 1000);

    jQuery('.ems_delivery').remove();

    jQuery('.group-remote-total').replaceWith(`
        <div class="group-remote-total">
            <div class="basket__total">
                <div class="basket__total_label">Вес товаров:</div>
                <div class="basket__total_sum total-sum">`+weight+`</div>
                <div class="basket__total_symbol">₽</div>
            </div>
            <div class="basket__total">
                <div class="basket__total_label">Доставка:</div>
                <div class="basket__total_sum total-sum">`+delivery+`</div>
                <div class="basket__total_symbol">₽</div>
            </div>
            <div class="basket__total">
                <div class="basket__total_label">Итого:</div>
                <div class="basket__total_sum total-sum">`+total+`</div>
                <div class="basket__total_symbol">₽</div>
            </div>
        </div>
    `);

    jQuery('[name="ems_address"]').val(data.indexTo+', '+data.regionTo+', '+data.areaTo+', '+data.cityTo+', '+data.addressTo);
    jQuery('[name="ems_weight"]').val(weight);
    jQuery('[name="ems_delivery_amount"]').val(delivery);
    jQuery('[name="ems_total_amount"]').val(total);
    jQuery('[name="ems_mail_type"]').val(data.mailType);

    let from = parseInt(data.deliveryDescription.values.deliveryMin) + 2;
    let to = parseInt(data.deliveryDescription.values.deliveryMax) + 2;
    let days_delivery = 'От '+from+' до '+to+'-x дней';

    jQuery('.ems_delivery_markup').text(days_delivery);
    jQuery('[name="ems_dalivery_date_description"]').val(days_delivery);
}

(function($) {
    jQuery.datetimepicker.setLocale('ru');

    let datepickerDefault = {
        format:'Y-m-d',
        inline:true,
        minDate: 0,
        formatDate:'Y-m-d',
        dayOfWeekStart: 1,
        theme: 'dark',
        todayButton: false,
        timepicker: false,
        onSelectDate: function(currentDatetime, input, event) {
            updateTimes();
        },
        i18n:{
            ru:{
                months:[
                    'Январь', 'Февраль', 'Март', 'Апрель',
                    'Май', 'Июнь', 'Июль', 'Август',
                    'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь',
                ],
                dayOfWeek:[
                    'Вс', 'Пн', 'Вт', 'Ср',
                    'Чт', 'Пт', 'Сб',
                ]
            }
        },
    };
    let datepickerOptions = $('.complex_time').data('options');
    datepickerOptions = Object.assign({}, datepickerDefault, datepickerOptions);
    $('.complex_date').datetimepicker(datepickerOptions);

    function updateTimes() {
        $.ajax({
            url: '/update-time',
            method: 'post',
            data: $('.request-form').serialize(),
            beforeSend: function() {
                $('.complex-datetime-group').addClass('load');
            },
            success: function(data) {
                if (data.success) {
                    $('.complex_time').datetimepicker('setOptions', data.timeOptions);
                    setTimeout(() => $(".xdsoft_time_variant").css({"margin-top": 0}), 10);
                }
            },
            complete: function() {
                $('.complex-datetime-group').removeClass('load');
            }
        });
    }

    let timepickerDefault = {
        format:'H:i',
        inline:true,
        formatTime:'H:i',
        theme: 'dark',
        datepicker: false,
    };
    let timepickerOptions = $('.complex_time').data('options');
    timepickerOptions = Object.assign({}, timepickerDefault, timepickerOptions);
    $('.complex_time').datetimepicker(timepickerOptions);

    $('body').on('submit', '.request-form', function(event) {
        event.preventDefault();
        $.ajax({
            url: '/request',
            method: 'post',
            dataType: 'json',
            data: $(this).serialize(),
            beforeSend: function() {
                $('.request-form').addClass('load');
            },
            success: function(data) {
                $('.request-form .recaptcha + .error').remove();
                if (!data.currentModal) {
                    if (data.success) {
                        let confirmModal = jQuery('[data-remodal-id="request-confirm"]').remodal();
                        $('.request-confirm-content').html(data.data);
                        confirmModal.open();
                        clearRequestForm();
                    } else {
                        $('.request-error-content').text(data.message);
                        let confirmModal = jQuery('[data-remodal-id="request-error"]').remodal();
                        confirmModal.open();
                    }
                }
                if (data.events) {
                    data.events.forEach(event => {
                        var customEvent = new CustomEvent(event, {detail : data});
                        document.dispatchEvent(customEvent);
                    });
                }
                updateTimes();
            },
            complete: function() {
                $('.request-form').removeClass('load');
            }
        });
    });

    $(document).on('request-recaptcha-error', function(event) {
        $('.request-form .recaptcha').after('<div class="error">'+event.detail.message+'</div>');
    });

    function clearRequestForm() {
        $('.request-form input[type="text"], .request-form textarea').each(function() {
            $(this).val('');
        });
    }
})(jQuery)